import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const HelloWorld = () => {
  const [currentTime, setCurrentTime] = useState('');
  const [token, setToken] = useState(localStorage.getItem('jwt'));
  const [pat, setPat] = useState(null);

  const apiHost = process.env.REACT_APP_API_HOST;
  const apiPort = process.env.REACT_APP_API_PORT;

  const validateToken = (token) => {
    try {
      const { exp } = jwtDecode(token);
      if (Date.now() >= exp * 1000) {
        localStorage.removeItem('jwt');
        return false;
      } else {
        return true;
      }
    } catch {
      return false;
    }
  };

  /**
   * Presence of code means we've auth'd (prob) so go find
   * a user and write it to local storage.
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      fetch(`${apiHost}:${apiPort}/auth/github/token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code }),
      })
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem('jwt', data.token);
          setToken(data.token);
        });
    }
  }, [apiHost, apiPort]);

  /**
   * Show the user a PAT they can play with.
   *
   * This will run when the page is loaded or when token changes.
   */
  useEffect(() => {
    const storedToken = localStorage.getItem('jwt');
    if (storedToken && validateToken(storedToken)) {
      const patUrl = `${apiHost}:${apiPort}/api/pat`;
      fetch(patUrl, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => setPat(data.pat));
    }
  }, [apiHost, apiPort, token]);

  /**
   * This serves no real purpose, just testing something out.
   *
   * This will run when the page is loaded or when token changes.
   */
  useEffect(() => {
    const storedToken = localStorage.getItem('jwt');
    if (storedToken && validateToken(storedToken)) {
      const apiUrl = `${apiHost}:${apiPort}/api/time`;
      fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => setCurrentTime(data.time));
    }
  }, [apiHost, apiPort, token]);

  const login = () => {
    const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
    const redirectUri = encodeURIComponent(window.location.href);
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}`;
  };

  return (
    <div>
      <h1>Hello from {process.env.NODE_ENV || '🤪'}</h1>
      {token && validateToken(token) ? (
        <>
          <p>Current Time: {currentTime}</p>
          {pat && <p>Your PAT: {pat}</p>}
        </>
      ) : (
        <button onClick={login}>Login with GitHub</button>
      )}
    </div>
  );
};

export default HelloWorld;
